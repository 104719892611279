import request from "./request";

// 获取会话列表
export const getSessionList = (params) => {
  return request.get("/buy", { params });
};

// 删除会话
export const delSession = (data = {}) => {
  return request.post("/delete", data);
};

// 修改会话名称
export const editSessionName = (data = {}) => {
  return request.post("/rename", data);
};

// 修改机器人名称
export const editRobotName = (data = {}) => {
  return request.post("/modify/seller_name", data);
};

// 删除标签
export const deleteTag = (data = {}) => {
  return request.post("/cancle", data);
};
