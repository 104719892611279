import "./sessionBox.css";
import { useEffect, useRef } from "react";
import dayjs from "dayjs";
import userAvatar from "@/assets/img/user_avatar.png";

const UserSession = ({ data, isLast }) => {
  const curRef = useRef(null);

  useEffect(() => {
    if (isLast) {
      curRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isLast]);

  return (
    <div className="session_item_region user_session_item" ref={curRef}>
      <div className="text_box">
        <div className="session_desc">{data.dialog || data.message}</div>
      </div>
      <div className="avatar_box user_avatar">
        <img className="avatar_img" src={userAvatar} alt="avatar"></img>

        <div className="name">您</div>
        <div className="session_time">
          {dayjs(data.created_at).format("HH:mm")}
        </div>
      </div>
    </div>
  );
};

export default UserSession;
