import { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import { Spin, Table } from "antd";
import sessionAvatar from "@/assets/img/session_case_avatar.png";
import "./sessionBox.css";

const RobotSession = ({ data, selectTag, isLast, seller }) => {
  // 当前选中的tag
  const [curTag, setCurTag] = useState("");
  const [hasDesc, setHasDesc] = useState(false);
  const curRef = useRef(null);

  const clickTag = (item) => {
    if (curTag || !isLast) {
      return;
    }
    selectTag(item);
    setCurTag(item);
  };

  useEffect(() => {
    console.log(isLast, "====");
    if (isLast) {
      curRef.current.scrollIntoView({ behavior: "smooth" });
    }
    setColumns([
      {
        title: "item",
        dataIndex: "item",
        key: "item",
        render: (_, { item }) => (
          <div className="left">
            <div
              className={
                isLast && (!curTag || curTag === item)
                  ? "list_tag_item select_tag"
                  : "list_tag_item"
              }
              onClick={() => clickTag(item)}
              key={item}
            >
              {item}
            </div>
          </div>
        ),
      },
      {
        title: "description",
        dataIndex: "description",
        key: "description",
      },
    ]);
  }, [isLast]);

  useEffect(() => {
    if (data.status === "loading") return;
    setHasDesc(!!data?.option?.filter((item) => item.description).length);
  }, [data]);

  const [columns, setColumns] = useState([
    {
      title: "item",
      dataIndex: "item",
      key: "item",
      render: (_, { item }) => (
        <div className="left">
          <div
            className={
              isLast && (!curTag || curTag === item)
                ? "list_tag_item select_tag"
                : "list_tag_item"
            }
            onClick={() => clickTag(item)}
            key={item}
          >
            {item}
          </div>
        </div>
      ),
    },
    {
      title: "description",
      dataIndex: "description",
      key: "description",
    },
  ]);

  return (
    <div className="h5_session_item_region" ref={curRef}>
      <div className="avatar_box">
        <img className="avatar_img" src={sessionAvatar} alt="avatar"></img>
        <div className="name">{seller.seller_name}</div>
        <div className="session_time">
          {dayjs(data.created_at).format("HH:mm")}
        </div>
      </div>
      {data.status === "loading" ? (
        <div className="text_box">
          <Spin
            size="middle"
            tip="收到，请稍等，我马上给您回复"
            className="loading_box"
          >
            <div className="content" />
          </Spin>
        </div>
      ) : (
        <div className="text_box">
          <div className="session_item_box">
            <div className="session_desc">{data.dialog || data.message}</div>
            {hasDesc ? (
              <Table
                columns={columns}
                dataSource={data.option}
                className="tag_table"
                bordered={true}
                size="small"
                pagination={false}
                showHeader={false}
              />
            ) : (
              <div className="tag_box">
                {data?.option?.map((item) => {
                  return (
                    <div
                      className={
                        isLast && (!curTag || curTag === item.item)
                          ? "tag_item select_tag"
                          : "tag_item"
                      }
                      onClick={() => clickTag(item.item)}
                      key={item.item}
                    >
                      {item.item}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RobotSession;
