import "./index.css";
import { message, Empty, Spin } from "antd";
import copy from "copy-to-clipboard";

const Product = ({ curServerData = { product: [] } }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const goUrl = (item) => {
    window.open(item.link, "__blank");
  };

  const handleCopy = (text) => {
    copy(text);
    messageApi.open({
      type: "success",
      content: "已经复制到剪切板",
    });
  };

  return (
    <Spin spinning={curServerData.localFlag !== undefined}>
      <div className="product_region">
        <p className="section_title">以下是给您推荐的京东商品</p>
        <div className="product_box">
          {curServerData.product.length ? (
            curServerData.product.map((item, index) => {
              return (
                <div className="product_item" key={index}>
                  <img
                    className="product_img"
                    src={item.pic_url}
                    alt={item.title}
                  ></img>
                  <div className="right_box">
                    <div className="top">
                      <div className="product_title">{item.title}</div>
                      <span className="product_price">{item.price}元</span>
                    </div>
                    <div className="bottom">
                      <div
                        className="oper_btn view_btn"
                        onClick={() => goUrl(item)}
                      >
                        去购买
                      </div>
                      <div
                        className="oper_btn copy_btn"
                        onClick={() => handleCopy(item.link)}
                      >
                        复制链接
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <Empty
              style={{
                margin: "0 auto",
              }}
              description={
                <span style={{ fontSize: "12px" }}>暂无商品推荐</span>
              }
            ></Empty>
          )}
        </div>
        {contextHolder}
      </div>
    </Spin>
  );
};

export default Product;
