import { useState, useEffect } from "react";
import { getSessionList, delSession } from "../../utils/session";
import Detail from "./components/sessionDetail/index";
import List from "./components/List/index";
import "./index.css";

const H5Page = () => {
  const [curServerData, setCurServerData] = useState({});
  const [showPage, setShowPage] = useState("list");

  useEffect(() => {
    reqServerData({});
  }, []);

  const reqServerData = async ({
    case_id = "",
    user_select = "",
    newFlag = 0,
  }) => {
    try {
      let res = await getSessionList({ case_id, user_select, new: newFlag });
      // res.product = [
      //   {
      //     pic_url:
      //       "https://img14.360buyimg.com/pop/jfs/t1/225925/19/12225/96533/65a9fc90Fd32d42aa/c6086495adc4d67c.jpg",
      //     title: "王老吉凉茶龙年大吉定制罐310ml*12罐礼盒装（新老包装随机发货）",
      //     price: 32.8,
      //     link: "https://union-click.jd.com/jdc?e=&p=JF8BAQQJK1olXwMBXFdcD00WCl8IG1odWwIBZBoCUBVIMzZNXhpXVhgcDBsJVFRMVnBaRQcLXQYDXFhZC1RORjNVKw1eAl5BLB0ebRdpWhp1SwkLGWBWBC5RBHsSA24IGFsUVQYAZF1cC08UA2gPHlIlbQYDZBwz3cKs1OuOzfGshaiGSIjispOEpF8JK1sTXAEEVVtZDkkVBm84G1MRbdGG0ofSiZOFvLu0pGslbQUyZG5dOEgnXQEJSFMVXARXXDAASktUR2pBRjUXXwcFVV9ZDk4nAW4JGVklbTZ_IQgjexJnaAhKYjBxK1JiBgMuawIQYwdmGSdVCQcECS4fb0gUfhpIHyASbQ",
      //   },
      //   {
      //     pic_url:
      //       "https://img14.360buyimg.com/pop/jfs/t1/181467/14/38571/94618/6507f75bFd88b7336/5e3c63e3be6a1754.jpg",
      //     title: "加多宝 凉茶植物饮料 茶饮料 310ml*12罐(新老包装随机发货)",
      //     price: 30.9,
      //     link: "https://union-click.jd.com/jdc?e=&p=JF8BAQAJK1olXwMAVVlfDkgVAF8IG1sVVA8EZBoCUBVIMzZNXhpXVhgcDBsJVFRMVnBaRQcLXQYCVFdUDlRORjNVKyNMWVV5IxgbbUNTBjtLfVJOHm5hTghRBHsSA24IGFsUVQYAZF1cC08UA2gPHlIlbQYDZBwz0O-Z1Oygzfu6iI-yjM7TOEonA2kJHF0UWAIEVltdCXsXC2s4zN-ThImDjMzi3PeoM184GGslbQYyV24DZkpEBW0IHlwSM1tAVB0ZDEoQbWwMG1scXAQCVG5fCUoVAV84K1tzAE0EFwkFfUxnRjN1SyFFCVR0MCkAeyUVZgdLYR18OXt3FR9dbgMfQTM4",
      //   },
      //   {
      //     pic_url:
      //       "https://img14.360buyimg.com/pop/jfs/t1/238886/29/2498/192956/659629d0Fe01e7769/e4332c2e91425959.jpg",
      //     title: "鲜京采 厄瓜多尔白虾1.5kg/盒 特大号20-30规格 单冻 年货礼品",
      //     price: 109,
      //     link: "https://union-click.jd.com/jdc?e=&p=JF8BAQMJK1olXwMAUlxaDEMXBF8NG1kQVQcCZBoCUBVIMzZNXhpXVhgcDBsJVFRMVnBaRQcLWAYAUVZcCFRORjNVKxwTNgJDUQgHbVUQfwhcYxATIkZiUT5RBHsSA24IGFsUVQYAZF1cC08UA2gPHlIlbQYDZBwz3-eL1-mnzN6OioSDgOjD3tKtM244G10UWgADUVpbCk4eBF8IE18lioKEjdHc0Nmo19O3K2slXjYyZF5tC3tJbW5bE1sXXgMDOgMfCAhTB2wPdVkXXAMLUF1bCnsVAm4KGWslbQdhXR0FYx5iBmcOGw1lHEQAIyMtfS9wWAEKfyNMXUdLKFlYSgkWYG9sbhgl",
      //   },
      // ];
      setCurServerData(res);
      // 用户的uuid
      localStorage.setItem("uuid", res.seller.uuid);
      if (newFlag) {
        setShowPage("detail");
      }
    } catch (error) {
      console.log("请求错误", error);
    }
  };

  // 切换会话
  const changeSession = (item) => {
    reqServerData({ case_id: item?.case_id });
  };

  // 刷新
  const refresh = () => {
    reqServerData({ case_id: curServerData?.case?.case_id });
  };

  const handleGoSessionDetail = () => {
    setShowPage("detail");
  };

  const deleteFun = async (e) => {
    e.stopPropagation();
    try {
      await delSession({ case_id: curServerData?.case?.case_id });
      // 删除之后默认展示列表第一个会话
      if (curServerData.case_list.length <= 1) {
        reqServerData({});
        return;
      }
      if (curServerData.case_list[0].case_id === curServerData.case.case_id) {
        reqServerData({ case_id: curServerData.case_list[1].case_id });
      } else {
        reqServerData({ case_id: curServerData.case_list[0].case_id });
      }
    } catch (error) {
      console.log("delete error", error);
    }
  };

  return showPage === "list" ? (
    <List
      reqServerData={reqServerData}
      curServerData={curServerData}
      deleteFun={deleteFun}
      goDetailFun={handleGoSessionDetail}
      changeSession={changeSession}
    ></List>
  ) : (
    <Detail
      reqServerData={reqServerData}
      curServerData={curServerData}
      refresh={refresh}
      setCurServerData={setCurServerData}
      goBackFun={() => setShowPage("list")}
    ></Detail>
  );
};

export default H5Page;
