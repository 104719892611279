import axios from "axios";
import { baseURL } from "./common";

const instance = axios.create({
  baseURL: baseURL,
  timeout: 60000,
  retry: 10,
  retryDelay: 500,
});

instance.interceptors.request.use(
  function (config) {
    config.headers.uuid = localStorage.getItem("uuid");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (err) {
    var config = err.config;
    // 如果不是超时直接返回错误
    if (
      err.code != "ECONNABORTED" ||
      !config ||
      err.message.indexOf("timeout") === -1
    )
      return Promise.reject(err);

    // 设置本次请求是第几此
    config.__retryCount = config.__retryCount || 1;

    // 当请求超过 7 此后将终止请求，返回错误信息
    if (config.__retryCount >= 7) {
      return Promise.reject(err);
    }
    // 请求次数加 1
    config.__retryCount += 1;

    // 创建一个新 promise 返回给调用接口的函数
    var backoff = new Promise(function (resolve) {
      setTimeout(function () {
        resolve();
      }, 1000);
    });

    // 返回 promise 返回给调用接口的函数，当到达一定时间(1000)后重新请求数据。
    return backoff.then(function () {
      return instance(config);
    });
  }
);

export default instance;
