import "./index.css";
import { useState, useEffect } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { editSessionName, deleteTag } from "@/utils/session";
import { CloseCircleFilled, ArrowLeftOutlined } from "@ant-design/icons";
import sessionImg from "@/assets/img/session_item_img.png";

const DescSection = ({ curSession, refresh, goBackFun }) => {
  const [editStatus, setEditStatus] = useState(false);
  const [newName, setNewName] = useState("");
  const [tagList, setTagList] = useState([]);

  // 为会话名称编辑框设置默认值
  useEffect(() => {
    setNewName(curSession?.case?.case_name || "");
    setTagList(curSession?.case?.label || []);
  }, [curSession]);

  const handleEditSession = () => {
    setEditStatus(!editStatus);
  };

  const saveFun = async () => {
    if (!newName || newName === curSession?.case?.case_name) {
      setEditStatus(false);
      return;
    }
    try {
      await editSessionName({
        case_id: curSession?.case?.case_id,
        new_name: newName,
      });
      // 可编辑态置为false
      setEditStatus(false);
      refresh();
    } catch (error) {
      console.log("edit case name error", error);
    }
  };

  const handleDeleteTag = async (tagItem) => {
    try {
      await deleteTag({
        case_id: curSession.case.case_id,
        label: tagItem.label_key,
      });
      setTagList(
        tagList.filter((item) => item.label_key !== tagItem.label_key)
      );
    } catch (error) {
      console.log("删除标签失败", error);
    }
  };

  return (
    <div className="h5_desc_section">
      <div className="top_region">
        <div className="left_box">
          <ArrowLeftOutlined className="go_back_page_btn" onClick={goBackFun} />
          <img src={sessionImg} className="session_icon" alt="会话" />
          {editStatus ? (
            <Input
              size="large"
              value={newName}
              onBlur={saveFun}
              className="session_name_input"
              onChange={(e) => {
                setNewName(e.target.value);
              }}
            ></Input>
          ) : (
            <p className="session_name">{curSession?.case?.case_name}</p>
          )}
        </div>

        <div className="icon_box" onClick={handleEditSession}>
          <EditOutlined className="oper_icon" />
        </div>
      </div>
      <div className="desc_box">
        {tagList.map((item, index) => {
          return (
            <span className="desc_item" key={item.label_key || index}>
              <CloseCircleFilled
                className="del_btn"
                onClick={() => handleDeleteTag(item)}
              />
              <span className="label">{item.label_desc}：</span>
              <span className="value">{item.label_value}</span>
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default DescSection;
