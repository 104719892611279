import { Input } from "antd";
import { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import cloneDeep from "lodash/cloneDeep";
import SessionList from "./components/SessionList/index";
import DescSection from "./components/DescSection/index";
import Product from "./components/Product/index";
import SessionRegion from "./components/SessionRegion/index";
import { getSessionList, delSession, editRobotName } from "../../utils/session";
import { PlusOutlined } from "@ant-design/icons";
import Avatar from "@/assets/img/case_avatar.png";
import "./index.css";

const IndexPage = () => {
  const [curServerData, setCurServerData] = useState({
    product: [],
  });
  const [editStatus, setEditStatus] = useState(false);
  const [robotName, setRobotName] = useState("");

  useEffect(() => {
    reqServerData({});
  }, []);

  const reqServerData = async ({
    case_id = "",
    user_select = "",
    newFlag = 0,
  }) => {
    try {
      let res = await getSessionList({ case_id, user_select, new: newFlag });
      // res.product = [
      //   {
      //     pic_url:
      //       "https://img14.360buyimg.com/pop/jfs/t1/225925/19/12225/96533/65a9fc90Fd32d42aa/c6086495adc4d67c.jpg",
      //     title: "王老吉凉茶龙年大吉定制罐310ml*12罐礼盒装（新老包装随机发货）",
      //     price: 32.8,
      //     link: "https://union-click.jd.com/jdc?e=&p=JF8BAQQJK1olXwMBXFdcD00WCl8IG1odWwIBZBoCUBVIMzZNXhpXVhgcDBsJVFRMVnBaRQcLXQYDXFhZC1RORjNVKw1eAl5BLB0ebRdpWhp1SwkLGWBWBC5RBHsSA24IGFsUVQYAZF1cC08UA2gPHlIlbQYDZBwz3cKs1OuOzfGshaiGSIjispOEpF8JK1sTXAEEVVtZDkkVBm84G1MRbdGG0ofSiZOFvLu0pGslbQUyZG5dOEgnXQEJSFMVXARXXDAASktUR2pBRjUXXwcFVV9ZDk4nAW4JGVklbTZ_IQgjexJnaAhKYjBxK1JiBgMuawIQYwdmGSdVCQcECS4fb0gUfhpIHyASbQ",
      //   },
      //   {
      //     pic_url:
      //       "https://img14.360buyimg.com/pop/jfs/t1/181467/14/38571/94618/6507f75bFd88b7336/5e3c63e3be6a1754.jpg",
      //     title: "加多宝 凉茶植物饮料 茶饮料 310ml*12罐(新老包装随机发货)",
      //     price: 30.9,
      //     link: "https://union-click.jd.com/jdc?e=&p=JF8BAQAJK1olXwMAVVlfDkgVAF8IG1sVVA8EZBoCUBVIMzZNXhpXVhgcDBsJVFRMVnBaRQcLXQYCVFdUDlRORjNVKyNMWVV5IxgbbUNTBjtLfVJOHm5hTghRBHsSA24IGFsUVQYAZF1cC08UA2gPHlIlbQYDZBwz0O-Z1Oygzfu6iI-yjM7TOEonA2kJHF0UWAIEVltdCXsXC2s4zN-ThImDjMzi3PeoM184GGslbQYyV24DZkpEBW0IHlwSM1tAVB0ZDEoQbWwMG1scXAQCVG5fCUoVAV84K1tzAE0EFwkFfUxnRjN1SyFFCVR0MCkAeyUVZgdLYR18OXt3FR9dbgMfQTM4",
      //   },
      //   {
      //     pic_url:
      //       "https://img14.360buyimg.com/pop/jfs/t1/238886/29/2498/192956/659629d0Fe01e7769/e4332c2e91425959.jpg",
      //     title: "鲜京采 厄瓜多尔白虾1.5kg/盒 特大号20-30规格 单冻 年货礼品",
      //     price: 109,
      //     link: "https://union-click.jd.com/jdc?e=&p=JF8BAQMJK1olXwMAUlxaDEMXBF8NG1kQVQcCZBoCUBVIMzZNXhpXVhgcDBsJVFRMVnBaRQcLWAYAUVZcCFRORjNVKxwTNgJDUQgHbVUQfwhcYxATIkZiUT5RBHsSA24IGFsUVQYAZF1cC08UA2gPHlIlbQYDZBwz3-eL1-mnzN6OioSDgOjD3tKtM244G10UWgADUVpbCk4eBF8IE18lioKEjdHc0Nmo19O3K2slXjYyZF5tC3tJbW5bE1sXXgMDOgMfCAhTB2wPdVkXXAMLUF1bCnsVAm4KGWslbQdhXR0FYx5iBmcOGw1lHEQAIyMtfS9wWAEKfyNMXUdLKFlYSgkWYG9sbhgl",
      //   },
      // ];
      setCurServerData(res);
      // 用户的uuid
      localStorage.setItem("uuid", res.seller.uuid);
      setRobotName(res.seller.seller_name);
    } catch (error) {
      console.log("请求错误", error);
    }
  };

  // 切换会话
  const changeSession = (item) => {
    reqServerData({ case_id: item?.case_id });
  };

  // 新增会话不必传参
  const handleAddSession = () => {
    reqServerData({ newFlag: 1 });
  };

  // 删除
  const handleDeleteSession = async () => {
    try {
      await delSession({ case_id: curServerData?.case?.case_id });
      // 删除之后默认展示列表第一个会话
      if (curServerData.case_list.length <= 1) {
        reqServerData({});
        return;
      }
      if (curServerData.case_list[0].case_id === curServerData.case.case_id) {
        reqServerData({ case_id: curServerData.case_list[1].case_id });
      } else {
        reqServerData({ case_id: curServerData.case_list[0].case_id });
      }
    } catch (error) {
      console.log("delete error", error);
    }
  };

  // 编辑会话名称
  const refresh = () => {
    reqServerData({ case_id: curServerData?.case?.case_id });
  };

  // 用户点选tag
  const selectTag = ({ tag }) => {
    beforeRequest({ tag });
    reqServerData({ case_id: curServerData?.case?.case_id, user_select: tag });
  };

  // 点选tag请求之前，先预反应，在请求展示loading
  const beforeRequest = ({ tag }) => {
    let temp = cloneDeep(curServerData);
    temp.history.push({
      role: "user",
      dialog: tag,
      created_at: new Date(),
    });
    temp.history.push({
      dialog: "收到，请稍等，我马上给您回复",
      case_id: temp.case.case_id,
      status: "loading",
      role: "assistant",
    });
    temp.localFlag = 1;
    setCurServerData(temp);
  };

  const handleChangeRobotName = (e) => {
    setRobotName(e.target.value);
  };

  const saveRobotName = async () => {
    try {
      await editRobotName({ seller_name: robotName });
      setEditStatus(false);
      refresh();
    } catch (error) {
      console.log("修改机器人名称失败");
    }
  };

  return (
    <div className="warp">
      <div className="session_container">
        <div className="left_region">
          <div className="avatar">
            <img className="avatar_img" src={Avatar} alt="头像"></img>
            <div className="robot_name">
              {editStatus ? (
                <Input
                  defaultValue={curServerData?.seller?.seller_name}
                  value={robotName}
                  onChange={handleChangeRobotName}
                  onBlur={saveRobotName}
                  className="edit_robot_name_input"
                ></Input>
              ) : (
                <span>{robotName || curServerData?.seller?.seller_name}</span>
              )}

              <EditOutlined
                className="edit_seller_name_icon"
                onClick={() => setEditStatus(true)}
              />
            </div>
          </div>
          <div className="session_list_container">
            <div className="add_btn" onClick={handleAddSession}>
              <PlusOutlined className="add_btn_icon" />
              买一件新东西
            </div>
            <SessionList
              changeSession={changeSession}
              curSession={curServerData}
              deleteSession={handleDeleteSession}
            />
          </div>
        </div>
        <div className="right_region">
          <DescSection curSession={curServerData} refresh={refresh} />
          <div className="session_region">
            <SessionRegion curSession={curServerData} selectTag={selectTag} />
          </div>
          <div className="product">
            <Product curServerData={curServerData} />
          </div>
        </div>
      </div>
      <div className="fillings"><a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2022026228号-3</a></div>
    </div>
  );
};

export default IndexPage;
