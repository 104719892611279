import "./index.css";
import { List } from "antd";
import dayjs from "dayjs";
import { CloseCircleFilled } from "@ant-design/icons";

const SessionListPage = ({ curSession = {}, changeSession, deleteSession }) => {
  const handleChangeSession = (item) => {
    changeSession(item);
  };
  const deleteFun = (e) => {
    e.stopPropagation();
    deleteSession();
  };

  return (
    <div className="session_list">
      <List
        itemLayout="horizontal"
        dataSource={curSession.case_list}
        renderItem={(item, index) => (
          <List.Item key={index} className="item_container">
            {curSession.case.case_id === item.case_id ? (
              <CloseCircleFilled
                className="delete_session_btn"
                onClick={deleteFun}
              />
            ) : null}
            <div
              className={
                curSession.case.case_id === item.case_id
                  ? "list_item active_item"
                  : "list_item"
              }
              onClick={() => handleChangeSession(item)}
            >
              <span className="case_name">{item.case_name}</span>
              <span className="case_created_at">
                {dayjs(item.created_at).format("HH:mm")}
              </span>
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default SessionListPage;
