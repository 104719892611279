import "./index.css";
import { useState } from "react";
import { message, Empty, Spin } from "antd";
import copy from "copy-to-clipboard";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const Product = ({ curServerData = { product: [] } }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isShow, setIsShow] = useState(true);
  const goUrl = (item) => {
    window.open(item.link, "__blank");
  };

  const handleCopy = (text) => {
    copy(text);
    messageApi.open({
      type: "success",
      content: "已经复制到剪切板",
    });
  };

  const toggleDisplay = (status) => {
    setIsShow(!isShow);
  };

  return (
    <Spin spinning={curServerData.localFlag !== undefined}>
      <div
        className={
          isShow ? "h5_product_region" : "h5_product_region h5_product_hide"
        }
      >
        <p className="section_title" onClick={toggleDisplay}>
          {isShow ? (
            <DownOutlined className="toggle_btn" />
          ) : (
            <UpOutlined className="toggle_btn" />
          )}
          以下是给您推荐的京东商品
        </p>
        <div className="product_box">
          {curServerData.product.length ? (
            curServerData.product.map((item) => {
              return (
                <div className="product_item" key={item.title}>
                  <span className="product_price">{item.price}元</span>
                  <img
                    className="product_img"
                    src={item.pic_url}
                    alt={item.title}
                  ></img>
                  <span className="product_title">{item.title}</span>
                  <div
                    className="oper_btn view_btn"
                    onClick={() => goUrl(item)}
                  >
                    去购买
                  </div>
                  <div
                    className="oper_btn"
                    onClick={() => handleCopy(item.link)}
                  >
                    复制链接
                  </div>
                </div>
              );
            })
          ) : (
            <Empty
              style={{
                margin: "0 auto",
              }}
              description={
                <span style={{ fontSize: "12px" }}>暂无商品推荐</span>
              }
            ></Empty>
          )}
        </div>
        {contextHolder}
      </div>
    </Spin>
  );
};

export default Product;
