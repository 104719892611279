import IndexPage from "./index/index";
import H5Page from "./h5/index";
import { useEffect, useState } from "react";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    checkMobile();
  }, []);

  const checkMobile = () => {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  return <div className="App">{isMobile ? <H5Page /> : <IndexPage />}</div>;
}

export default App;
