import DescSection from "./components/DescSection/index";
import Product from "./components/Product/index";
import SessionRegion from "./components/SessionRegion/index";
import cloneDeep from "lodash/cloneDeep";
import "./index.css";

const Detail = ({
  goBackFun,
  curServerData,
  refresh,
  setCurServerData,
  reqServerData,
}) => {
  // 用户点选tag
  const selectTag = ({ tag }) => {
    beforeRequest({ tag });
    reqServerData({ case_id: curServerData?.case?.case_id, user_select: tag });
  };
  // 点选tag请求之前，先预反应，在请求展示loading
  const beforeRequest = ({ tag }) => {
    let temp = cloneDeep(curServerData);
    temp.history.push({
      role: "user",
      dialog: tag,
      created_at: new Date(),
    });
    temp.history.push({
      dialog: "收到，请稍等，我马上给您回复",
      case_id: temp.case.case_id,
      status: "loading",
      role: "assistant",
    });
    temp.localFlag = 1;
    setCurServerData(temp);
  };

  return (
    <div className="h5_detail_page">
      <DescSection
        curSession={curServerData}
        refresh={refresh}
        goBackFun={goBackFun}
      />
      <div className="session_region">
        <SessionRegion curSession={curServerData} selectTag={selectTag} />
      </div>
      <div className="product">
        <Product
          localFlag={curServerData.localFlag}
          curServerData={curServerData}
        />
      </div>
    </div>
  );
};

export default Detail;
