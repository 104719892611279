import "./index.css";
import RobotSession from "./robot";
import UserSession from "./user";
import { useState, useEffect, useRef } from "react";

const SessionRegion = ({ curSession, selectTag }) => {
  const [allSessionList, setAllSessionList] = useState([]);

  useEffect(() => {
    if (!Object.keys(curSession).length) return;
    setAllSessionList([]);
    if (curSession?.history?.length) {
      setAllSessionList(curSession?.history);
    }
  }, [curSession]);

  const sessionRef = useRef(null);

  const clickTag = (tag) => {
    selectTag({ tag: tag });
  };

  return (
    <div className="h5_session_box" ref={sessionRef}>
      {allSessionList.map((item, index) => {
        return item.role === "user" ? (
          <UserSession
            data={item}
            key={`${item.case_id}${index}`}
            isLast={index === allSessionList.length - 1}
          ></UserSession>
        ) : (
          <RobotSession
            selectTag={clickTag}
            data={item}
            key={`${item.case_id}${index}`}
            isLast={index === allSessionList.length - 1}
            seller={curSession.seller}
          ></RobotSession>
        );
      })}
    </div>
  );
};

export default SessionRegion;
