import { useState, useEffect } from "react";
import { List, Input } from "antd";
import dayjs from "dayjs";
import {
  EditOutlined,
  PlusOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { editRobotName } from "@/utils/session";
import Avatar from "@/assets/img/case_avatar.png";

const H5Page = ({ goDetailFun, deleteFun, curServerData, reqServerData }) => {
  const [robotName, setRobotName] = useState("index");
  const [editStatus, setEditStatus] = useState(false);

  useEffect(() => {
    setRobotName(curServerData?.seller?.seller_name);
  }, [curServerData]);

  // 切换会话
  const changeSession = (item) => {
    goDetailFun();
    reqServerData({ case_id: item?.case_id });
  };

  // 新增会话
  const handleAddSession = () => {
    reqServerData({ newFlag: 1 });
  };

  // 编辑会话名称
  const refresh = () => {
    reqServerData({ case_id: curServerData?.case?.case_id });
  };

  const handleChangeRobotName = (e) => {
    setRobotName(e.target.value);
  };

  const saveRobotName = async () => {
    try {
      await editRobotName({ seller_name: robotName });
      setEditStatus(false);
      refresh();
    } catch (error) {
      console.log("修改机器人名称失败");
    }
  };

  return (
    <div className="h5_index_page">
      <div className="avatar">
        <img className="avatar_img" src={Avatar} alt="头像"></img>
        <div className="robot_name">
          {editStatus ? (
            <Input
              defaultValue={curServerData?.seller?.seller_name}
              value={robotName}
              onChange={handleChangeRobotName}
              onBlur={saveRobotName}
              className="edit_robot_name_input"
            ></Input>
          ) : (
            <span>{robotName || curServerData?.seller?.seller_name}</span>
          )}

          <EditOutlined
            className="edit_seller_name_icon"
            onClick={() => setEditStatus(true)}
          />
        </div>
      </div>
      <div className="add_btn" onClick={handleAddSession}>
        <PlusOutlined className="add_btn_icon" />
        买一件新东西
      </div>
      <List
        itemLayout="horizontal"
        dataSource={curServerData.case_list}
        renderItem={(item, index) => (
          <List.Item key={index} className="item_container">
            {curServerData.case.case_id === item.case_id ? (
              <CloseCircleFilled
                className="delete_session_btn"
                onClick={deleteFun}
              />
            ) : null}
            <div
              className={
                curServerData.case.case_id === item.case_id
                  ? "list_item active_item"
                  : "list_item"
              }
              onClick={() => changeSession(item)}
            >
              <span className="case_name">{item.case_name}</span>
              <span className="case_created_at">
                {dayjs(item.created_at).format("HH:mm")}
              </span>
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default H5Page;
